import { defaults } from 'axios';
import { store } from '../root/store';
import history from '../root/browser-history';
import { LOGIN_AUTH_TOKEN, LOGIN_USER, LOGIN_USER_ID } from '../root/local-storage-keys';
import endpoints from './endpoints';
import getConfig from "../utils/config"

const config = {};
const getStore = () => config.store || store;

// Useful for mocking api in tests.
export const setApiStore = (mock) => {
  config.store = mock;
};

export default function Api(args) {
  getStore().dispatch({
    type: 'API_REQUEST',
    payload: { request: args.request, action: buildAction(args) },
  });
}

function buildAction({ request, id, params, data }) {
  const { verb, path, pathEnd } = endpoints[request];

  const port = !!getConfig("API_PORT") ? `:${getConfig("API_PORT")}` : ""
  const server = `${getConfig("API_HTTP_SCHEME")}://${getConfig("API_DOMAIN")}${port}`;

  // Filter out null sections and concatenate them as a path
  const url = [server, path, id, pathEnd].filter((x) => x).join('/');

  return () => verb(url, data || { params });
}

export const setAuthToken = (token) => {
  localStorage.setItem(LOGIN_AUTH_TOKEN, token);
  defaults.headers.common.Authorization = token;
};

export const getAuthToken = () => defaults.headers.common.Authorization;

export const setUser = ({ email, id }) => {
  localStorage.setItem(LOGIN_USER, email);
  localStorage.setItem(LOGIN_USER_ID, id);
  defaults.headers.common.User = email;
  defaults.headers.common.UserId = id;
};

export const isAuthenticated = () => !!defaults.headers.common.Authorization;

export const logout = () => {
  localStorage.removeItem(LOGIN_AUTH_TOKEN);
  defaults.headers.common.Authorization = undefined;
  setTimeout(() => history.push('/login'), 1);
};

const initiate = () => {
  if (!defaults.headers.common.Authorization) {
    defaults.headers.common.Authorization = localStorage.getItem(LOGIN_AUTH_TOKEN);
    defaults.headers.common.User = localStorage.getItem(LOGIN_USER);
    defaults.headers.common.UserId = localStorage.getItem(LOGIN_USER_ID);
  }
};

initiate();
